import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom";
import './Homepage.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import TypingEffect from "./TypingEffect";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Timer from "./Timer";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Homepage() {
  const [state, setState] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState([]);
  const [username, setUsername] = useState('');
  const [addChat, setAddChat] = useState([]);
  const [latestResponseIndex, setLatestResponseIndex] = useState(null);
  const [docName, setDocName] = useState('');
  const [popupContent, setPopupContent] = useState('');
  const [circular, setCircular] = useState("hidden");
  const [respCheck, setRespCheck] = useState(false);
  const [toggleResp, setToggleResp] = useState(0);
  const [open, setOpen] = useState(false);
  const chatContainerRef = useRef(null);
  const [chatId, setChatId] = useState(1);
  const { usertype, customerID, UserName } = useParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 //const url = "http://164.52.205.159:8000"
  const url = "https://shrmlegalassistance.shrmpro.com"

  const statesList = [
    { id: 1, name: 'ANDHRA PRADESH' },
    { id: 2, name: 'ASSAM' },
    { id: 3, name: 'ARUNACHAL PRADESH' },
    { id: 4, name: 'BIHAR' },
    { id: 5, name: 'GUJARAT' },
    { id: 6, name: 'HARYANA' },
    { id: 7, name: 'HIMACHAL PRADESH' },
    { id: 8, name: 'JAMMU and KASHMIR' },
    { id: 9, name: 'KARNATAKA' },
    { id: 10, name: 'KERALA' },
    { id: 11, name: 'MADHYA PRADESH' },
    { id: 12, name: 'MAHARASHTRA' },
    { id: 13, name: 'MANIPUR' },
    { id: 14, name: 'MEGHALAYA' },
    { id: 15, name: 'MIZORAM' },
    { id: 16, name: 'NAGALAND' },
    { id: 17, name: 'ODISHA' },
    { id: 18, name: 'PUNJAB' },
    { id: 19, name: 'RAJASTHAN' },
    { id: 20, name: 'SIKKIM' },
    { id: 21, name: 'TAMIL NADU' },
    { id: 22, name: 'TRIPURA' },
    { id: 23, name: 'UTTAR PRADESH' },
    { id: 24, name: 'WEST BENGAL' },
    { id: 25, name: 'DELHI' },
    { id: 26, name: 'GOA' },
    { id: 27, name: 'PONDICHERRY' },
    { id: 28, name: 'LAKSHADWEEP' },
    { id: 29, name: 'DAMAN and DIU' },
    { id: 30, name: 'DADRA and NAGAR HAVELI' },
    { id: 31, name: 'CHANDIGARH' },
    { id: 32, name: 'ANDAMAN and NICOBAR ISLANDS' },
    { id: 33, name: 'UTTARANCHAL' },
    { id: 34, name: 'JHARKHAND' },
    { id: 35, name: 'CHHATTISGARH' },
    { id: 36, name: 'LADAKH' },
    { id: 37, name: 'TELAGANA' }
  ];


  const handleChangeState = (event) => {
    setState(event.target.value);
  };

  const handleChangeQuestion = (event) => {
    const word = event.target.value
    setQuestion(word);
  };

  const handleLinkClick = (fileLink, fileName) => {
    // handleOpen();
    setDocName(fileName.replace(/_/g, ' ').toUpperCase());
    setPopupContent(fileLink);
  };

  const handleComplete = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const doc = async () => {
    try {

      if (state === '') {
        alert("Please select state")
        return
      }
      setAnswer((prevResults) => [
        ...prevResults,
        { content: question, type: 'user' },
      ]);
      setRespCheck(false);
      setQuestion('')
      setCircular("visible");
      const resp = await axios.post(`${url}/lawbot`, {
        question,
        option: state,
        UserName: username ? username : "Administrator",
      });
      if (resp.data.error) {
        setAnswer((prevResults) => {
          const updatedResults = [
            ...prevResults,
            { content: resp.data.error, type: 'response', },
          ];
          setLatestResponseIndex(updatedResults.length - 1); // Track the index of the latest response
          return updatedResults;
        });
      } else {
        setAnswer((prevResults) => {
          const updatedResults = [
            ...prevResults,
            { content: resp.data.data, formDta: resp.data.forms_data, type: 'response', flag: resp.data.data[0]?.reponse?.from_doc },
          ];
          setLatestResponseIndex(updatedResults.length - 1); // Track the index of the latest response
          return updatedResults;
        });
      }
      // if (resp.data[0]?.reponse?.from_doc === true) {
      //   setRespCheck(true);
      // }
      setCircular("hidden");
    } catch (error) {
      console.log(error);
    }
  };
  const formatTextWithBoldNumbers = (text) => {
    return text.split("\n").map((line, index) => {
      const parts = line.split(/\b(\d+)\b/);
      return (
        <React.Fragment key={index}>
          {parts.map((part, partIndex) => {

            const isNumber = /^\d+$/.test(part.trim());
            return isNumber ? <strong key={partIndex}>{part}</strong> : part;
          })}
          <br />
        </React.Fragment>
      );
    });
  };
  console.log(answer)

  const newChat = async () => {
    try {
      const resp = await axios.post(`${url}/getchatid`, {
        "username": username ? username : "Administrator",
      })
      setChatId(resp.data.Data)

      if (answer.length > 0) {
        setAddChat((prevResults) => {
          const existingChatIndex = prevResults.findIndex(chat => chat.id === toggleResp);
          if (existingChatIndex !== -1) {
            // If the chat already exists, update its content
            const updatedChats = [...prevResults];
            updatedChats[existingChatIndex].content = answer;
            return updatedChats;
          } else {
            // If the chat doesn't exist, add a new chat
            const addChats = { content: answer, type: answer[0].content, id: Date.now() };
            return [...prevResults, addChats];
          }
        });
      }
      setAnswer([]);
    } catch (error) {
      console.log(error)
    }
  };

  const addChats = async () => {

    try {
      const json = JSON.stringify(answer)
      const correctJson = json.replace(/"/g, "'")

      const history = await axios.post(`${url}/chats`, {
        "Text": json,
        "username": username ? username : "Administrator",
        "chatID": chatId
      })
    } catch (error) {
      console.log(error)
    }

  }

  const getChats = async () => {
    try {
      const response = await axios.post(`${url}/getchats`, {
        "username": username ? username : "Administrator",
      });
      console.log(response.data.Data, "yyyyy")
      if (response.data.Data) {
        const chatObjects = response.data.Data;
        const parsedArray = chatObjects.map((chatObj) => {
          console.log(chatObj)
          return {
            id: chatObj.id,
            chat: chatObj.chat,
          };
          // if (chatObj.chat) {
          //   try {
          //     const jsonParse = chatObj.chat
          //       .replace(/'/g, '"')
          //       .replace(/Decimal\(([^)]+)\)/g, "$1")
          //       .replace(/[\n\r\t]+/g, ' ') // Combine newline, carriage return, and tab replacements
          //       .replace(/None/g, 'null')
          //       .replace(/\\/g, '/'); // Adjusted to ensure correct escaping

          //     const parsedChat = JSON.parse(jsonParse);
          //     console.log(parsedChat, "parsedChat");
          //     return {
          //       id: chatObj.id,  // Assuming there is an 'id' property in each chatObj
          //       chat: parsedChat,
          //     };
          //   } catch (error) {
          //     console.error(`Error parsing chat string: ${chatObj.chat}`, error);
          //     return null;
          //   }
          // } else {
          //   console.error("Chat object is missing 'chat' property:", chatObj);
          //   return null;
          // }
        });

        setAddChat(parsedArray.filter(Boolean)); // Remove null values
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const handleDownload = (e, fileLink, fileName) => {
    e.preventDefault(); // Prevents default navigation
    const a = document.createElement('a');
    a.href = fileLink;
    a.setAttribute('download', fileName); // Force download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const updateChat = (content, id) => {

    setAnswer(content)
    setToggleResp(id)
    setChatId(id)
  };
  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);
    // Extract username parameter from the URL
    const usernameParam = url.searchParams.get('username');
    setUsername(usernameParam);
  }, []);

  useEffect(() => {
    if (answer.length > 0) {
      addChats()
    }

    if (answer) {
      getChats()
    }

  }, [answer])

  useEffect(() => {
    axios.post(`${url}/getchatid`, {
      "username": username ? username : "Administrator",
    })
      .then((resp) => {
        setChatId(resp.data.Data);
      })
      .catch((error) => {
        console.error("Error fetching chat ID:", error);
      });
  }, [UserName]);

  return (
    <>
      <div className="chatbot-content">
        <div className="sidebar">
          <div className="chats">
            <button className="btn btn-primary" onClick={() => newChat()}>
              <i className="fa fa-plus-circle"></i> New Chat
            </button>

          </div>
          <div className="previous-chat">
            <h1>History</h1>
            <ul>
              {addChat.length > 0 && addChat.map((chats, i) => {

                const content = chats?.chat?.[0]?.content;
                console.log(content)
                return (
                  <li key={i} onClick={() => {
                    updateChat(chats.chat, chats.id);
                  }}>
                    <a >{content}</a>
                  </li>
                );
              })}


            </ul>
          </div>
          <div className="profile">
            <button className="tab" data-call="upgrade">Upgrade to Premium</button>
            <button className="tab">mail@sphinxworldbiz.com</button>
          </div>
        </div>

        <div className="chatbox">
          <div className="header">
            <div className="header-logo">
              <img src="img/logo.png" alt="logo" /> Pilot
            </div>
            <div className="sidebar-searchbox">
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="demo-simple-select-autowidth-label">States</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={state}
                  onChange={handleChangeState}
                  autoWidth
                  label="States"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {statesList.map((state) => (
                    <MenuItem key={state.id} value={state.name}>{state.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="chatbox-body">
            <div className="chat-content" ref={chatContainerRef}>
              <div className="status">
                <h5 key={0}>03-Jan-2024</h5>
              </div>
              <div>
                {answer.length > 0 && answer.map((item, index) => {
                  if (item.type === 'response' && Array.isArray(item.content)) {
                    return item.flag ?
                      (
                        <div key={index} className="card">
                          {item.content.map((contentItem, contentIndex) => (
                            <div key={contentIndex} className="row">
                              <div className="col-md-8">
                                <p><span className="strong">Page No:-</span> {contentItem.reponse?.metadata?.page_num.replace(/\D/g, '')}</p>
                                <p><span className="strong">Document Name:-</span> {contentItem.reponse?.doc_path.replace(/_/g, ' ').toUpperCase()}</p>
                                <p><span className="strong">Answer:-</span>
                                  {index === latestResponseIndex ? (
                                    <TypingEffect text={contentItem.reponse?.answer} speed={5} onComplete={handleComplete} />
                                  ) : (
                                    typeof contentItem.reponse?.answer === 'string' ? formatTextWithBoldNumbers(contentItem.reponse?.answer) : ''
                                  )}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p><span className="strong">Reference Link</span></p>
                                <a href={contentItem.reponse?.file_link} target="_blank" rel="noopener noreferrer">
                                  {contentItem.reponse?.doc_path.replace(/_/g, ' ').toUpperCase()}
                                </a>
                                {/* <a href="#" onClick={() => handleLinkClick(contentItem.reponse?.file_link, contentItem.reponse?.doc_path)}>
                                  {contentItem.reponse?.doc_path.replace(/_/g, ' ').toUpperCase()}
                                </a> */}
                              </div>

                            </div>
                          ))}
                          {/* <div className="row">


                            {item.formDta.length > 0 && item.formDta.map((data, i) => (
                              <div className="col-md-4">
                              {console.log(data,"dataaaaa",data.reponse.file_data)}
                              {data?.reponse?.file_data?.length >0& data.reponse.file_data.map((link)=>(
                                <>
                                <p><span className="strong">Form Link</span></p>
                                <a href={link.file_link} download >
                                  {link.doc_path + link.file_name}
                                </a>
                                </>
                              ))}
                                
                               
                              </div>
                            ))}

                          </div> */}
                          <div className="row">
                            {item.formDta.length > 0 &&
                              item.formDta.map((data, i) => (
                                <div className="col-md-4" key={i}>
                                  {console.log(data, "dataaaaa", data?.reponse?.file_data)}
                                  {data?.reponse?.file_data?.length > 0 &&
                                    data.reponse.file_data.map((link, index) => (
                                      <React.Fragment key={index}>
                                        <p>
                                          <span className="strong">Form Link</span>
                                        </p>
                                        <a href={link.file_link} target="_blank" rel="noopener noreferrer">
                                          {data?.reponse?.doc_path + link.file_name}
                                        </a>
                                      </React.Fragment>
                                    ))}
                                </div>
                              ))}
                          </div>
                        </div>

                      ) : (
                        <div className="card" key={index}>
                          <div className="row">
                            <div className="col-md-8">
                              <p className="title-text">Answer</p>
                            </div>
                            <div className="col-md-4">
                              <p className="title-text">Reference Link</p>
                            </div>
                          </div>
                          {item.content.map((contentItem, contentIndex) => (
                            <div key={contentIndex} className="row">
                              <div className="col-md-8">
                                <p className="title">
                                  {index === latestResponseIndex ? (
                                    <TypingEffect text={contentItem.reponse?.answer?.text} speed={5} onComplete={handleComplete} />
                                  ) : (
                                    typeof contentItem.reponse?.answer?.text === 'string' ? formatTextWithBoldNumbers(contentItem.reponse?.answer?.text) : ''
                                  )}
                                </p>
                              </div>
                              <div className="col-md-4">
                                {contentItem.reponse?.web_data.map((webItem, i) => (
                                  <div key={i}>
                                    <p className="sub-title">Tittle:-{typeof webItem.title === 'string' ? webItem.title : ''}</p>
                                    <p className="sub-title">Answer:-{typeof webItem.link_ans === 'string' ? webItem.link_ans : ''}</p>
                                    {/* <a href={typeof webItem.link === 'string' ? webItem.link : '#'} className="anchor-link">{typeof webItem.link === 'string' ? webItem.link : ''}</a> */}
                                    <a href={typeof webItem.link === 'string' ? webItem.link : '#'} className="anchor-link" target="_blank" rel="noopener noreferrer">
                                      {typeof webItem.link === 'string' ? webItem.link : ''}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                  } else if (item.type === 'user') {
                    return (
                      <div key={index} className="question"><span className="question-text">{item.content}</span></div>
                    )
                  } else if (item.type === 'response') {
                    return (
                      // formatTextWithBoldNumbers(item.content)
                      <div key={index} className=""><span className="question-text">{item.content}</span></div>
                    )
                  }
                  return null;
                })}


                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      {docName}
                    </Typography>
                    <Typography id="modal-modal-description" height="400px" sx={{ mt: 2 }}>
                      <iframe src={popupContent} width="100%" height="100%" frameborder="0"></iframe>

                    </Typography>
                  </Box>
                </Modal>
              </div>


              {/* {respCheck === true && (
  <div>
    {answer.length > 0 && answer.map((item, index) => (
      item.type === 'response' && Array.isArray(item.content) && (
        <div key={index} className="card">
          {item.content.map((contentItem, contentIndex) => (
            <div key={contentIndex} className="row">
              <div className="col-md-8">
                <p><span className="strong">Page No:-</span> {contentItem.reponse?.metadata?.page_num.replace(/\D/g, '')}</p>
                <p><span className="strong">Document Name:-</span> {contentItem.reponse?.doc_path.replace(/_/g, ' ').toUpperCase()}</p>
                <p><span className="strong">Answer:-</span> 
                  {index === latestResponseIndex ? (
                    <TypingEffect text={contentItem.reponse?.answer} speed={5} onComplete={handleComplete} />
                  ) : (
                    typeof contentItem.reponse?.answer === 'string' ? contentItem.reponse?.answer : ''
                  )}
                </p>
              </div>
              <div className="col-md-4">
                <p><span className="strong">Reference Link</span></p>
                <a href="#" onClick={() => handleLinkClick(contentItem.reponse?.file_link, contentItem.reponse?.doc_path)}>
                  {contentItem.reponse?.doc_path.replace(/_/g, ' ').toUpperCase()}
                </a>
              </div>
            </div>
          ))}
        </div>
      )
    ))}
  </div>
)} */}

              {/* {respCheck === false && (
  <div>
    {answer.length > 0 && answer.map((item, index) => (
      item.type === 'response' && Array.isArray(item.content) && (
        <div className="card" key={index}>
          <div className="row">
            <div className="col-md-8">
              <p className="title-text">Answer</p>
            </div>
            <div className="col-md-4">
              <p className="title-text">Reference Link</p>
            </div>
          </div>
          {item.content.map((contentItem, contentIndex) => (
            <div key={contentIndex} className="row">
              <div className="col-md-8">
                <p className="title">
                  {index === latestResponseIndex ? (
                    <TypingEffect text={contentItem.reponse?.answer?.text} speed={5} onComplete={handleComplete} />
                  ) : (
                    typeof contentItem.reponse?.answer?.text === 'string' ? contentItem.reponse?.answer?.text : ''
                  )}
                </p>
              </div>
              <div className="col-md-4">
                {contentItem.reponse?.web_data.map((webItem, i) => (
                  <div key={i}>
                    <p className="sub-title">{typeof webItem.title === 'string' ? webItem.title : ''}</p>
                    <a href="#" className="anchor-link">{typeof webItem.link === 'string' ? webItem.link : ''}</a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )
    ))}
  </div>
)} */}



            </div>
            <div className="typer">
              <textarea
                className="form-control"
                placeholder="Enter your prompt here"
                id="analyticsTxtbox"
                autoComplete="off"
                value={question}
                onChange={handleChangeQuestion}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    doc();
                    e.target.blur();
                  }
                }}
              ></textarea>

              <div className="btn-send">
                <button className="circle-button danger-bg">
                  <i className="fa fa-microphone"></i>
                </button>
                <button className="circle-button success-bg">
                  <i className="fa fa-paper-plane" onClick={doc}></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {circular === "visible" && <div className="loader-bg">
        <div className="loader-box">
          <div className="loader"></div>
          <img src="../img/company.png" alt="logo" />
          <div className="loader-txt">Loading</div>
          <div className="timer-txt"> <Timer resetTimer={circular} /> </div>
        </div>
      </div>}
    </>
  );
}

export default Homepage;
